/* Portfolio.css */

.portfolio {
    padding: 60px 0;
  }
  
  .portfolio .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .portfolio #portfolio-flters {
    padding: 0;
    margin: 0 auto 30px auto;
    list-style: none;
    text-align: center;
  }
  
  .portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 10px 22px;
    margin: 0 5px 10px 5px;
    font-size: 14px;
    font-weight: 600;
    color: #444;
    background: #fff;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  
  .portfolio #portfolio-flters li:hover,
  .portfolio #portfolio-flters li.filter-active {
    color: #fff;
    background: #3498db;
  }
  
  .portfolio .portfolio-item {
    margin-bottom: 30px;
  }
  
  .portfolio .portfolio-wrap {
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  }
  
  .portfolio .portfolio-wrap img {
    transition: 0.3s;
  }
  
  .portfolio .portfolio-wrap:hover img {
    transform: scale(1.1);
  }
  
  .portfolio .portfolio-info {
    padding: 25px 15px 15px 15px;
  }
  
  .portfolio .portfolio-info h4 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .portfolio .portfolio-info h4 a {
    color: #444;
  }
  
  .portfolio .portfolio-info p {
    color: #aaa;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .portfolio .portfolio-links {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 15px;
    top: 15px;
    opacity: 0;
    transition: all 0.3s;
  }
  
  .portfolio .portfolio-links a {
    color: #fff;
    background: #3498db;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    transition: 0.3s;
    margin-left: 10px;
  }
  
  .portfolio .portfolio-links a:hover {
    background: #3fbbc0;
  }
  
  .portfolio .portfolio-wrap:hover .portfolio-links {
    opacity: 1;
  }
  