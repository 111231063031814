/* Add this CSS to give some spacing between the header and the detail section */
.mt-5 {
    margin-top: 5rem;
  }
  
  .what-we-do-detail {
    padding: 60px 0;
    background: #f8f9fa;
  }
  
  .what-we-do-detail .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .what-we-do-detail .img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  .what-we-do-detail p {
    font-size: 18px;
    color: #666;
    line-height: 1.6;
  }
  
  /* Responsive Styles */
  @media (max-width: 767px) {
    .what-we-do-detail {
      padding: 30px 0;
    }
  
    .what-we-do-detail .section-title {
      padding-bottom: 15px;
    }
  
    .what-we-do-detail p {
      font-size: 16px;
    }
  }
  