/* About.css */

.about {
    padding: 60px 0;
  }
  
  .about .img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  .about h3 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #444;
  }
  
  .about p {
    font-size: 15px;
    color: #6c757d;
  }
  
  .about ul {
    list-style: none;
    padding: 0;
  }
  
  .about ul li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .about ul li i {
    color: #3fbbc0;
    font-size: 20px;
    margin-right: 10px;
  }
  
  .about .icon-boxes .icon-box {
    padding: 30px;
    margin-bottom: 20px;
    background: #fff;
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  
  .about .icon-boxes .icon-box i {
    font-size: 28px;
    color: #3fbbc0;
    margin-bottom: 10px;
  }
  
  .about .icon-boxes .icon-box h4 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #444;
  }
  
  .about .icon-boxes .icon-box p {
    font-size: 14px;
    color: #6c757d;
  }
  
  
  .uniform-dimensions {
    width: 100%;
    height: 150px; /* Set your desired height here */
    object-fit: cover;
  }
  
  .main-img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  