@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

// Custom theme colors
$theme-colors: (
  "primary": #ffcd00, // Yellow color from the logo
  "secondary": #333333, // Black color from the logo
  "success": #28a745,
  "info": #3498db,
  "warning": #ffc107,
  "danger": #dc3545,
  "light": #f8f9fa,
  "dark": #343a40
);

@import "~bootstrap/scss/bootstrap";

// Define CSS variables for custom colors
:root {
  --bs-primary: #ffcd00;
  --bs-secondary: #333333;
  --bs-success: #28a745;
  --bs-info: #3498db;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #343a40;
}

// Override Bootstrap default colors
body {
  color: var(--bs-dark);
}

a {
  color: var(--bs-primary);
  &:hover {
    color: var(--bs-dark);
  }
}

// Add any additional custom styles below
