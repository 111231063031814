#footer {
    background: #fff;
    padding: 20px 0;
    color: #444;
    font-size: 14px;
  }
  
  #footer .footer-top {
    background: #f1f1f1;
    padding: 60px 0 30px 0;
  }
  
  #footer .footer-contact {
    text-align: center;
  }
  
  #footer .footer-contact img.footer-logo {
    max-width: 150px;
    margin-bottom: 20px;
  }
  
  #footer .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    color: #444;
  }
  
  #footer .footer-links h4 {
    font-size: 16px;
    font-weight: bold;
    color: #444;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footer .footer-links ul i {
    font-size: 18px;
    color: #4fbfa8;
    padding-right: 8px;
  }
  
  #footer .footer-links ul a {
    color: #777;
    transition: 0.3s;
  }
  
  #footer .footer-links ul a:hover {
    color: #4fbfa8;
  }
  
  #footer .footer-newsletter {
    padding-top: 10px;
    text-align: right; /* Align the newsletter section to the right */
  }
  
  #footer .footer-newsletter h4 {
    font-size: 16px;
    font-weight: bold;
    color: #444;
    padding-bottom: 12px;
  }
  
  #footer .footer-newsletter p {
    font-size: 14px;
    line-height: 24px;
    color: #777;
    margin-bottom: 0;
  }
  
  #footer .footer-newsletter form {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Align form items to the end */
    margin-top: 15px;
  }
  
  #footer .footer-newsletter form input[type="email"] {
    border: 0;
    padding: 10px;
    width: 100%;
    max-width: 300px;
    margin-bottom: 10px;
  }
  
  #footer .footer-newsletter form input[type="submit"] {
    background: #4fbfa8;
    border: 0;
    padding: 10px 20px;
    cursor: pointer;
    color: #fff;
    transition: 0.3s;
  }
  
  #footer .footer-newsletter form input[type="submit"]:hover {
    background: #3fa491;
  }
  
  #footer .footer-bottom {
    background: #f1f1f1;
    padding: 10px 0;
  }
  
  #footer .footer-bottom .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  #footer .copyright {
    margin-bottom: 10px;
  }
  
  #footer .credits {
    font-size: 13px;
    color: #777;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    #footer .footer-top .container .row {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    #footer .footer-top .container .row .col-lg-3,
    #footer .footer-top .container .row .col-lg-2,
    #footer .footer-top .container .row .col-lg-4 {
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
    }
  
    #footer .footer-newsletter form {
      flex-direction: column;
      align-items: center;
    }
  
    #footer .footer-newsletter form input[type="email"] {
      width: 100%;
      max-width: 300px;
      margin-bottom: 10px;
    }
  
    #footer .footer-newsletter form input[type="submit"] {
      width: 100%;
      max-width: 150px;
    }
  }
  