/* Team.css */

.team {
    padding: 60px 0;
    background: #fff;
  }
  
  .team .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .team .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .team .member {
    text-align: center;
    background: #f8f9fa;
    padding: 30px;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .team .member img {
    margin-bottom: 15px;
    border-radius: 50%;
    width: 150px;
    height: 150px;
  }
  
  .team .member h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 20px;
    color: #333;
  }
  
  .team .member span {
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: #aaa;
    margin-bottom: 10px;
  }
  
  .team .member .social {
    margin-top: 15px;
  }
  
  .team .member .social a {
    color: #007bff;
    margin: 0 10px;
    display: inline-block;
  }
  
  .team .member .social a:hover {
    color: #0056b3;
  }
  
  .team .quote {
    background: #f8f9fa;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .team .quote p {
    font-style: italic;
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
    position: relative;
    padding: 0 20px;
  }
  
  .team .quote .quote-icon-left,
  .team .quote .quote-icon-right {
    font-size: 2em;
    color: #007bff;
    position: absolute;
  }
  
  .team .quote .quote-icon-left {
    top: -10px;
    left: -20px;
  }
  
  .team .quote .quote-icon-right {
    transform: scaleX(-1);  /* This flips the icon horizontally */
    bottom: -10px;
    right: -20px;
  }
  
  .team .quote .author {
    font-size: 14px;
    color: #555;
    text-align: right;
    margin-top: 20px;
  }
  