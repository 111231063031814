.collections {
    padding: 60px 0;
    background: #fff;
}

.collections .section-title {
    text-align: center;
    padding-bottom: 30px;
}

.collections .icon-box {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 30px;
    text-align: center;
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
}

.collections .icon-box:hover {
    transform: translateY(-10px);
}

.collections .image {
    margin-bottom: 15px;
}

.collections .image img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 8px;
}

.collections h4 {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 20px;
}

.collections h4 a {
    color: #000;
    text-decoration: underline;
    transition: color 0.3s ease;
}

.collections h4 a:hover {
    color: var(--bs-primary); /* Theme primary color */
}

.collections p {
    font-size: 14px;
    color: #777;
}
