/* Contact.css */

.contact {
    padding: 60px 0;
    background: #f7f7f7;
}

.contact .section-title {
    text-align: center;
    padding-bottom: 30px;
}

.contact .info-wrap {
    background: #fff;
    padding: 30px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.contact .info {
    margin-bottom: 20px;
}

.contact .info i {
    font-size: 20px;
    color: #007bff;
    float: left;
    width: 44px;
    height: 44px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 10px rgba(1, 41, 112, 0.08);
    margin-right: 15px;
}

.contact .info h4 {
    font-size: 22px;
    color: #333;
    font-weight: 700;
    margin-bottom: 10px;
}

.contact .info p {
    font-size: 14px;
    color: #777;
}

.contact .php-email-form {
    width: 100%;
    background: #fff;
    padding: 30px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.contact .php-email-form .form-group {
    margin-bottom: 15px;
}

.contact .php-email-form .loading {
    display: none;
    color: #007bff;
    font-size: 15px;
    text-align: center;
    padding: 15px;
}

.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #007bff;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .text-center button {
    background: #007bff;
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
}

.contact .php-email-form .text-center button:hover {
    background: #0056b3;
}
