/* Counts.css */

.counts {
    padding: 60px 0;
    background: var(--bs-light);
  }
  
  .count-box {
    text-align: center;
    padding: 30px 20px;
    background: #fff;
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  
  .count-box i {
    font-size: 48px;
    color: var(--bs-primary); /* Updated to use theme color */
    margin-bottom: 10px;
  }
  
  .count-box .purecounter {
    display: block;
    font-size: 32px;
    font-weight: 700;
    color: var(--bs-secondary);
  }
  
  .count-box p {
    margin: 0;
    font-size: 15px;
    color: var(--bs-gray-dark);
  }
  
  .count-box .icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }

  
  .counts .count-box {
    text-align: center;
    padding: 30px 0;
    width: 100%;
    background: #f7f7f7;
    transition: all 0.3s ease-in-out;
  }
  
  .counts .count-box i, .counts .count-box svg {
    font-size: 48px;
    color: #47b2e4;
    margin-bottom: 15px;
  }
  
  .counts .count-box span {
    font-size: 36px;
    display: block;
    font-weight: 700;
    color: #000;
  }
  
  .counts .count-box p {
    padding: 0;
    margin: 0;
    font-size: 16px;
    color: #000;
  }
  

  @media (min-width: 992px) {
    .count-box {
      margin-top: 0 !important;
    }
  }


  