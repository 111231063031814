#header {
    background: var(--bs-light);
    transition: all 0.5s;
    z-index: 997;
    height: 70px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  #header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  #header .logo {
    font-size: 32px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  
  #header .logo a {
    color: var(--bs-secondary);
    text-decoration: none;
  }
  
  #header .logo img.logo-img {
    max-width: 250px;
    height: auto;
  }
  
  #header .navbar {
    margin-left: auto;
  }
  
  #header .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
  }
  
  #header .navbar ul li {
    position: relative;
  }
  
  #header .navbar ul li a {
    display: block;
    position: relative;
    color: var(--bs-secondary);
    padding: 10px 15px;
    text-decoration: none;
    transition: 0.3s;
    font-size: 14px;
  }
  
  #header .navbar ul li a.active,
  #header .navbar ul li a:hover {
    color: var(--bs-primary);
  }
  
  #header .navbar ul li a.scrollto {
    cursor: pointer;
  }
  
  #header .mobile-nav-toggle {
    display: none;
    cursor: pointer;
    font-size: 28px;
    color: var(--bs-secondary);
  }
  
  .header-social-links {
    display: flex;
    align-items: center;
  }
  
  .header-social-links a {
    color: var(--bs-secondary);
    margin-left: 10px;
    transition: 0.3s;
  }
  
  .header-social-links a:hover {
    color: var(--bs-primary);
  }
  
  .header-social-links .mobile-nav-toggle {
    display: block;
    margin-left: 15px;
    cursor: pointer;
  }
  
  /* Dropdown Styles */
  #header .navbar .dropdown > a:after {
    content: "\f078";
    font-family: "FontAwesome";
    padding-left: 5px;
  }
  
  #header .navbar .dropdown ul {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 99;
    background: #fff;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    margin: 0;
    list-style: none;
  }
  
  #header .navbar .dropdown ul li {
    position: relative;
  }
  
  #header .navbar .dropdown ul li a {
    padding: 10px 20px;
    font-size: 14px;
    text-decoration: none;
    display: block;
    color: var(--bs-secondary);
  }
  
  #header .navbar .dropdown ul li a:hover {
    color: var(--bs-primary);
    background: #f8f9fa;
  }
  
  #header .navbar .dropdown:hover > ul {
    display: block;
  }
  
  /* Mobile Styles */
  @media (max-width: 992px) {
    #header .navbar {
      display: none;
      position: fixed;
      top: 70px;
      right: 0;
      background: var(--bs-light);
      width: 100%;
      height: calc(100% - 70px);
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
      transition: 0.3s;
      z-index: 999;
      flex-direction: column;
      align-items: center;
      overflow-y: auto;
    }
  
    #header .navbar.navbar-mobile {
      display: flex;
    }
  
    #header .navbar ul {
      flex-direction: column;
      width: 100%;
    }
  
    #header .navbar ul li {
      width: 100%;
    }
  
    #header .navbar ul li a {
      padding: 15px 20px;
      width: 100%;
      text-align: center;
    }
  
    #header .mobile-nav-toggle {
      display: block;
    }
  
    .header-social-links {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
  
    .header-social-links a {
      margin-left: 10px;
    }
  
    .header-social-links .mobile-nav-toggle {
      margin-left: 15px;
    }
  }
  