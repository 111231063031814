.what-we-do {
    padding: 60px 0;
}

.what-we-do .section-title {
    text-align: center;
    padding-bottom: 30px;
}

.what-we-do .section-title h2 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: var(--bs-dark);
    position: relative;
}

.what-we-do .section-title p {
    margin-bottom: 0;
    font-size: 15px;
    color: var(--bs-secondary);
}

.what-we-do .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    background: var(--bs-light);
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    text-align: center;
    cursor: pointer;
}

.what-we-do .icon-box .image {
    margin: 0 auto 20px auto;
    width: 250px;
    height: 250px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.what-we-do .icon-box .image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.what-we-do .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
    transition: color 0.3s ease;
}



.what-we-do .icon-box h4 a {
    color: var(--bs-dark);
    text-decoration: underline;
    transition: color 0.3s ease;
}

.what-we-do .icon-box:hover h4 a:hover {
    color: var(--bs-primary); /* Theme primary color when hovering directly on the text */
}

.what-we-do .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.what-we-do .icon-box:hover {
    transform: translateY(-10px);
}
