/* AppHero.css */

.app-hero {
    padding: 60px 0;
    background: #f8f9fa;
  }
  
  .app-hero .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .app-hero .app-hero-text {
    max-width: 500px;
  }
  
  .app-hero .app-hero-text h1 {
    font-size: 48px;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
  }
  
  .app-hero .app-hero-text p {
    font-size: 18px;
    color: #666;
    margin-bottom: 30px;
  }
  
  .app-hero .download-buttons {
    display: flex;
    gap: 20px;
  }
  
  .app-hero .btn {
    font-size: 16px;
    padding: 10px 20px;
  }
  
  .app-hero .btn i {
    margin-right: 10px;
  }
  
  .app-hero .app-hero-images {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .app-hero .app-hero-images img {
    max-width: 300px;
    margin: 0 10px;
    transform: rotate(-10deg);
  }
  
  .app-hero .app-hero-images img:nth-child(2) {
    transform: rotate(10deg);
  }
  
  @media (max-width: 767px) {
    .app-hero .container {
      flex-direction: column;
      text-align: center;
    }
  
    .app-hero .app-hero-images {
      margin-top: 30px;
    }
  
    .app-hero .app-hero-images img {
      transform: rotate(0deg); /* Remove rotation on smaller screens */
    }
  
    .app-hero .app-hero-images img.hide-on-small-screen {
      display: none; /* Hide the second image on smaller screens */
    }
  
    .app-hero .download-buttons {
      flex-direction: column;
      align-items: center; /* Center align the buttons */
    }
  
    .app-hero .btn {
      width: 100%;
      max-width: 250px;
      margin: 5px 0;
      text-align: center; /* Center the button text */
    }
  }
  