/* Portfolio Details */
.portfolio-main-image img {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .portfolio-thumbnails {
    display: flex;
    gap: 10px;
  }
  
  .portfolio-thumbnails img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border 0.3s;
  }
  
  .portfolio-thumbnails img.active,
  .portfolio-thumbnails img:hover {
    border: 2px solid var(--bs-primary);
  }
  