#hero {
  width: 100%;
  height: 100vh;
  background-size: cover;
  position: relative;
}

#hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.5); /* Adjust transparency as needed */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#hero .container {
  z-index: 2;
  position: relative; /* Ensure container is positioned relatively to use z-index */
}

#hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: bold;
  color: #fff;
}

#hero h1 span {
  color: var(--bs-primary); /* Highlight color for the brand name */
}

#hero h2 {
  color: #eee;
  margin: 10px 0 20px 0;
  font-size: 24px;
}

#hero .btn-get-started {
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 35px;
  border-radius: 50px;
  transition: 0.5s;
  margin-top: 10px;
  color: #fff;
  background: var(--bs-primary);
}

#hero .btn-get-started:hover {
  background: var(--bs-dark); /* Darker shade on hover */
}

.logo-container {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
}

.fotr-logo {
  width: 100px;
  height: auto;
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 32px;
  }

  #hero h2 {
    font-size: 18px;
  }

  .fotr-logo {
    width: 80px;
  }
}
